import React from "react";
import styles from "./styles";
import { AuthContext } from "../../context/auth";
import TabsViews from "../TabsViews";

// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import { Grid, Box, Hidden, Typography } from "@material-ui/core/";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

//Recursos
import Logo from "../../assets/image/Logo.png";
// import Coljuegos from "../../assets/image/coljuegos.png";
// import LogosApoyo from "../../assets/image/logos_que_apoyan.png";
// import LogosCol from "../../assets/image/coljuegos.png";

class Dashboard extends React.Component {
  state = {
    openDialog: false,
    message: "¿Estas seguro que quieres cantar bingo?",
    user: null,
    selected: [],
  };

  render() {
    const { user, transmision, bingoSing } = this.context;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid
          container
          className={classes.container}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ marginBottom: "10px" }}>
            <Hidden smUp>
              <Box
                display='flex'
                justifyContent='center'
                style={{ marginBottom: "20px" }}>
                <img
                  src={Logo}
                  alt='Logo'
                  width='40%'
                  height='100%'
                />
              </Box>
            </Hidden>
            <p>(((•))) SEÑAL EN VIVO Y EN DIRECTO</p>
            <div className={classes.playerWrapper}>
              {transmision === user.userData.transmision ? (
                <iframe
                  src='https://player.twitch.tv/?channel=linkmys&parent=cantemosbingo.com'
                  frameBorder='0'
                  scrolling='no'
                  className={classes.reactPlayer}
                  width='100%'
                  height='100%'
                  title='Transmisión'></iframe>
              ) : transmision === "911" ? (
                <iframe
                  src='https://player.twitch.tv/?channel=linkmys_2&parent=cantemosbingo.com'
                  frameBorder='0'
                  scrolling='no'
                  className={classes.reactPlayer}
                  width='100%'
                  height='100%'
                  title='Transmisión'></iframe>
              ) : (
                <iframe
                  className={classes.reactPlayer}
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/NIq8a0eHAAE?autoplay=1&mute=0&rel=0'
                  allow='autoplay; encrypted-media'
                  title='Transmisión'></iframe>
              )}
            </div>
            <Typography
              variant='h5'
              color='textSecondary'
              style={{ marginTop: "5px", color: "#fff", fontWeight: "bold" }}>
              {/* BINGO - #BuenaBingoMamá */}
            </Typography>
            <Hidden xsDown>
              {/* <Box display="flex" justifyContent="center" my={2}>
                <img src={Patrocinadores} alt="Patrocinadores" width="90%" />
              </Box> */}
              <Typography
                variant='subtitle2'
                color='textSecondary'
                align='justify'
                style={{ marginTop: "20px", color: "#FFFFFF" }}>
                <b>INSTRUCCIONES:</b>
                <br></br>
                <br></br>
                1) Marca y desmarca las casillas dando clic sobre cada número en
                tu cartón.<br></br>
                2) Cuando completes la línea o el cartón lleno, debes oprimir el
                botón ¡BINGO!.<br></br>
                3) Si oprimes el botón BINGO 3 veces de manera errada, tu cartón
                será bloqueado hasta que finalice el juego que está en curso.
                <br></br>
                4) Nuestro sistema automáticamente organiza a las personas que
                cantaron BINGO, de la más rápidas a la más lenta, así sabremos
                quién es el ganador; es decir, la primera persona que ha cantado
                BINGO.<br></br>
                {/* 5) El jugador que gane un BINGO no podrá ganar nuevamente.
                <br></br> */}
              </Typography>

              {/* <Box
                display="flex"
                justifyContent="center"
                className={classes.coljuegos}
              >
                <img src={LogosCol} alt="LogosCol" height="100%" width="50%" />
              </Box> */}
            </Hidden>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}>
            <Hidden xsDown>
              <Box
                display='flex'
                justifyContent='center'
                style={{ marginBottom: "20px" }}>
                <img
                  src={Logo}
                  alt='Logo'
                  width='30%'
                  height='100%'
                />
              </Box>
            </Hidden>
            <TabsViews
              updateUser={this.context.updateUser}
              user={user.userData}
              bingoSing={bingoSing}
              transmision={transmision}
            />

            {/* <Box
              display="flex"
              justifyContent="center"
              className={classes.marcas}
            >
              <img
                src={LogosApoyo}
                alt="LogosApoyo"
                className={classes.marcasImg}
              />
            </Box> */}

            <Hidden smDown>
              <div className={classes.flotante}>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <div className={classes.pack}>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send?phone=573009109963",
                          "_blank"
                        );
                      }}>
                      <span>
                        <WhatsAppIcon className={classes.icon} />
                      </span>
                      <span className={classes.textSupport}>Soporte</span>
                    </Box>
                  </div>
                </Box>
              </div>
            </Hidden>
            <Hidden smUp>
              {/* <Box display="flex" justifyContent="center" my={2}>
                <img
                  src={LogoPatroTlf}
                  alt="Patrocinadores"
                  width="100%"
                  height="100%"
                />
              </Box> */}
              <Typography
                variant='subtitle2'
                color='textSecondary'
                align='justify'
                style={{ marginTop: "20px", color: "#000000" }}>
                <b>INSTRUCCIONES:</b>
                <br></br>
                <br></br>
                1) Marca y desmarca las casillas dando clic sobre cada número en
                tu cartón.<br></br>
                2) Cuando completes la línea o el cartón lleno, debes oprimir el
                botón ¡BINGO!.<br></br>
                3) Si oprimes el botón BINGO 3 veces de manera errada, tu cartón
                será bloqueado hasta que finalice el juego que está en curso.
                <br></br>
                4) Nuestro sistema automáticamente organiza a las personas que
                cantaron BINGO, de la más rápidas a la más lenta, así sabremos
                quién es el ganador; es decir, la primera persona que ha cantado
                BINGO.<br></br>
                {/* 5) El jugador que gane un BINGO no podrá ganar nuevamente. */}
                <br></br>
              </Typography>
              {/* <Box
                display="flex"
                justifyContent="center"
                className={classes.coljuegos}
              >
                <img src={LogosCol} alt="LogosCol" height="100%" width="50%" />
              </Box> */}
              <div className={classes.flotante}>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  mt={2}>
                  <div className={classes.pack}>
                    <Box
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() => {
                        window.open(
                          "https://api.whatsapp.com/send?phone=573009109963",
                          "_blank"
                        );
                      }}>
                      <span>
                        <WhatsAppIcon className={classes.icon} />
                      </span>
                      <span className={classes.textSupport}>Soporte</span>
                    </Box>
                  </div>
                </Box>
              </div>
            </Hidden>
          </Grid>
        </Grid>
        <div className={classes.flotante2}></div>
      </React.Fragment>
    );
  }
}

Dashboard.contextType = AuthContext;

export default withStyles(styles)(Dashboard);
