import React from "react";
// import { DialogContentText } from "@material-ui/core/";
// import Data from "../../assets/image/data.pdf";

export default class HabeusData extends React.Component {
  render() {
    return (
      <div
        id='alert-dialog-slide-description'
        color='textPrimary'
        align='justify'>
        <h2>Tratamiento de datos personales</h2>
        <p>
          En cumplimiento a la ley 1581 de 2012, y decreto Reglamentario 1377 de
          2013 y nuestra Política de Protección de Datos Personales, esta
          información será tratada de forma segura. Autorizas de manera previa,
          explicita e inequívoca a la ORGANIZACIÓN JAMAR, para el tratamiento de
          tus datos personales, dicha política se encuentra publicada en
          www.jamar.com
        </p>
      </div>
    );
  }
}
